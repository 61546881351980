import React, { useState } from 'react';
import { ListItemButton, ListItemText, ListItemSecondaryAction, Typography, IconButton } from '@mui/material';
import { DeleteOutlined } from '@mui/icons-material';
import { NotificationIcon } from './NotificationIcon';
import NotificationDescription from './NotificationDescription';
import { getNotificationColor } from './constants';
import { momentWithUserTZ } from 'utils/momentWithTz';

const NotificationListItem = ({ 
  notification, 
  onDelete,
  onNotificationClick,
  className, 
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleDelete = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    
    if (isLoading) {
      return;
    }
    
    setIsLoading(true);
    try {
      await onDelete(notification.uuid, notification.source);
    } catch (error) {
      console.error('Failed to delete notification:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleClick = async () => {
    if (isLoading || !onNotificationClick) {
      return;
    }
    
    setIsLoading(true);
    try {
      await onNotificationClick();
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <ListItemButton
      divider
      selected={!notification.is_read}
      onClick={handleClick}
      disabled={isLoading}
      className={className}
      sx={{
        opacity: isLoading ? 0.7 : 1,
        pointerEvents: isLoading ? 'none' : 'auto',
        cursor: isLoading ? 'default' : 'pointer',
        transition: 'opacity 0.2s ease',
        '&:hover': {
          backgroundColor: isLoading ? 'transparent' : undefined,
        },
      }}
    >
      <NotificationIcon notification={notification} />
      <ListItemText
        primary={
          <>
            <Typography
              color={getNotificationColor(notification.type)}
              fontSize={16}
              fontWeight={400}
            >
              {notification.heading}
            </Typography>
            <NotificationDescription
              description={notification.description}
              notificationData={notification.data || {}}
            />
          </>
        }
        secondary={
          <Typography color="#8C8C8C" fontSize={12} fontWeight={400}>
            {momentWithUserTZ(notification.created_at).fromNow()}
          </Typography>
        }
      />
      <ListItemSecondaryAction>
        <Typography color="#8C8C8C" fontSize={12} fontWeight={400} noWrap>
          {momentWithUserTZ(notification.created_at).format('h:mm a')}
        </Typography>
        <IconButton
          variant="text"
          color="inherit"
          aria-label="delete"
          disabled={isLoading}
          sx={{ 
            background: 'none !important',
            opacity: isLoading ? 0.5 : 1,
          }}
          onClick={handleDelete}
        >
          <DeleteOutlined color="disabled" />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItemButton>
  );
};

export default NotificationListItem;
