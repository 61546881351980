import { NOTIFICATION_SOURCES, NOTIFICATION_TYPES } from './notifications/types';

/**
 * Transform Laravel notification to standard format
 */
export const transformNotification = (notification) => {
  const { id, type, data, read_at, created_at } = notification;
  const notificationData = typeof data === 'string' ? JSON.parse(data) : data;
  
  return {
    uuid: id, // Laravel uses id instead of uuid
    type: notificationData.type || 'info', // Get type from data or default
    heading: notificationData.heading,
    description: notificationData.description,
    created_at,
    is_read: Boolean(read_at),
    data: notificationData,
    source: NOTIFICATION_SOURCES.LARAVEL,
    sourceType: type, // Original Laravel notification type
  };
};
  
/**
   * Transform legacy notification to standard format
   */
export const transformLegacyNotification = notification => ({
  ...notification,
  source: NOTIFICATION_SOURCES.LEGACY,
  is_read: notification.is_read ?? false,
});
  
/**
   * Transform broadcast event to notification format
   */
export const transformBroadcastEvent = (event) => {
  console.log('Received broadcast event:', event);
  // Handle different broadcast event types
  switch (event.type) {
    case NOTIFICATION_TYPES.IMPORT.PROGRESS:
      return {
        uuid: `${event.data?.import_id}-progress`,
        type: 'info',
        heading: event.heading || 'Import Progress',
        description: event.description,
        created_at: new Date().toISOString(),
        is_read: true, // Progress events are always considered read
        data: event.data,
        source: 'broadcast',
        isTemporary: true,
        progress: event.data?.progress,
      };
      
    // Add more broadcast event types here
    case NOTIFICATION_TYPES.IMPORT.COMPLETED:
    case NOTIFICATION_TYPES.IMPORT.ERROR:
    case NOTIFICATION_TYPES.IMPORT.STARTED:
      return {
        uuid: event.data?.import_id ?? event.id,
        type: event.type === NOTIFICATION_TYPES.IMPORT.ERROR ? 'error' : 'success',
        heading: event.heading,
        description: event.description,
        created_at: new Date().toISOString(),
        is_read: false,
        data: event.data,
        source: NOTIFICATION_SOURCES.BROADCAST,
        needsSync: true, // Flag to indicate this needs to be synced with backend
      };
  
    default:
      // Generic broadcast event transform
      return {
        uuid: event.id || `broadcast-${Date.now()}`,
        type: event.type || 'info',
        heading: event.heading,
        description: event.description,
        created_at: new Date().toISOString(),
        is_read: false,
        data: event.data,
        source: NOTIFICATION_SOURCES.BROADCAST,
      };
  }
};
