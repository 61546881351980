import Pusher from 'pusher-js';
import Echo from 'laravel-echo';
import { BROADCASTER_TYPES, getBroadcastingConfig, getAuthHeaders } from '../utils/broadcasting';
import axiosInstance from 'utils/axios';

export class BroadcastingService {
  constructor() {
    this.echo = null;
    this.pusher = null;
  }

  initialize(config, csrfToken, store) {
    const { driver, config: driverConfig } = getBroadcastingConfig(config);
    const authHeaders = getAuthHeaders(csrfToken, store);

    // Initialize Pusher instance based on driver
    if (driver === BROADCASTER_TYPES.PUSHER) {
      this.pusher = new Pusher(config.pusherKey, driverConfig);
      this.setupPusherListeners();
    } else {
      this.pusher = Pusher;
    }

    // Initialize Echo
    this.echo = new Echo({
      broadcaster: driver,
      ...driverConfig,
      pusher: this.pusher,
      authEndpoint: `${config.apiHost}/broadcasting/auth`,
      host: config.apiHost.replace(/^https?:\/\//, ''),
      auth: {
        headers: authHeaders,
      },
      authorizer: channel => ({
        authorize: async (socketId, callback) => {
          try {
            const response = await axiosInstance.post('/broadcasting/auth', {
              socket_id: socketId,
              channel_name: channel.name,
            });

            callback(null, response.data);
          } catch (error) {
            console.error('Broadcasting auth error:', error?.response?.data || error);
            callback(error);
          }
        },
      }),
    });

    // Attach to window for legacy support
    window.Echo = this.echo;
    window.Pusher = this.pusher;

    return {
      echo: this.echo,
      pusher: this.pusher,
    };
  }

  setupPusherListeners() {
    if (this.pusher.connection) {
      this.pusher.connection.bind('connected', () => {
        console.log('Connected to Pusher');
      });

      this.pusher.connection.bind('error', (err) => {
        console.error('Pusher connection error:', err);
      });
    }
  }

  getChannelName(channelName, tenant) {
    return `${channelName}`;
  }

  subscribeToChannel(channelName, events = {}, type = 'private') {
    if (!this.echo) {
      console.error('Echo not initialized');
      return null;
    }

    let channel;
    switch (type) {
      case 'presence':
        channel = this.echo.join(channelName);
        break;
      case 'private':
        channel = this.echo.private(channelName);
        break;
      default:
        channel = this.echo.channel(channelName);
    }

    Object.entries(events).forEach(([event, callback]) => {
      channel.listen(event, callback);
    });

    return channel;
  }

  cleanup() {
    if (this.echo) {
      this.echo.disconnect();
    }
  }
}

export const broadcastingService = new BroadcastingService();
