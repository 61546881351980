import { createApi } from '@reduxjs/toolkit/query/react';
import getCustomBaseQuery from './customBaseQuery';
import { 
  transformNotification, 
  transformLegacyNotification, 
} from '../utils/notificationTransformer';

const notificationsApi = createApi({
  reducerPath: 'notificationsApi',
  baseQuery: getCustomBaseQuery(),
  tagTypes: ['Notification', 'LegacyNotification'],
  endpoints: builder => ({
    // Get legacy notifications
    getLegacyNotifications: builder.query({
      query: () => ({
        url: '/notifications',
        params: {
          per_page: 50,
          sort: 'created_at',
          sort_direction: 'desc',
        },
      }),
      transformResponse: (response, meta, arg) => {
        // Add safe check for response
        if (!response?.data) {
          return [];
        }
        return response.data.map(transformLegacyNotification);
      },
      providesTags: ['LegacyNotification'],
    }),

    // Get Laravel notifications (imports etc)
    getNotifications: builder.query({
      query: () => ({
        url: '/notifications/new',
        params: {
          per_page: 50,
          sort: 'created_at',
          sort_direction: 'desc',
        },
      }),
      transformResponse: (response, meta, arg) => {
        // Add safe check for response
        if (!response?.data) {
          return [];
        }
        return response.data.map(transformNotification);
      },
      providesTags: ['Notification'],
    }),

    // Mark Laravel notifications as read
    markNotificationsAsRead: builder.mutation({
      query: (notifications) => {
        // Ensure we're sending proper data structure
        const body = Array.isArray(notifications) 
          ? { notifications } 
          : notifications;

        return {
          url: '/notifications/new/read',
          method: 'PUT',
          data: body,
          // Explicitly set headers if needed
          headers: {
            'Content-Type': 'application/json',
          },
        };
      },
      invalidatesTags: ['Notification'],
      // Optimistic update
      async onQueryStarted(notifications, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(
            notificationsApi.util.updateQueryData(
              'getNotifications', 
              undefined, 
              (draft) => {
                const notificationIds = Array.isArray(notifications) 
                  ? notifications 
                  : [notifications];
                
                draft.forEach((notification) => {
                  if (notificationIds.includes(notification.uuid)) {
                    notification.is_read = true;
                  }
                });
              },
            ),
          );
        } catch {
          // If the mutation fails, the query will be automatically refetched
        }
      },
    }),

    // Mark legacy notifications as read
    markLegacyNotificationsAsRead: builder.mutation({
      query: ids => ({
        url: '/notifications/read',
        method: 'PUT',
        data: { notifications: ids },
      }),
      invalidatesTags: ['LegacyNotification'],
      // Optimistic update
      async onQueryStarted(ids, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(
            notificationsApi.util.updateQueryData(
              'getLegacyNotifications', 
              undefined, 
              (draft) => {
                draft.forEach((notification) => {
                  if (ids.includes(notification.uuid)) {
                    notification.is_read = true;
                  }
                });
              },
            ),
          );
        } catch {
          // If the mutation fails, the query will be automatically refetched
        }
      },
    }),

    // Delete Laravel notification
    deleteNotification: builder.mutation({
      query: id => ({
        url: `/notifications/new/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Notification'],
      // Optimistic update
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          notificationsApi.util.updateQueryData(
            'getNotifications',
            undefined,
            draft => draft.filter(notification => notification.uuid !== id),
          ),
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),

    // Delete legacy notification
    deleteLegacyNotification: builder.mutation({
      query: id => ({
        url: `/notifications/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['LegacyNotification'],
      // Optimistic update
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          notificationsApi.util.updateQueryData(
            'getLegacyNotifications',
            undefined,
            draft => draft.filter(notification => notification.uuid !== id),
          ),
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),
  }),
});

// Export hooks
export const {
  useGetLegacyNotificationsQuery,
  useGetNotificationsQuery,
  useMarkNotificationsAsReadMutation,
  useMarkLegacyNotificationsAsReadMutation,
  useDeleteNotificationMutation,
  useDeleteLegacyNotificationMutation,
} = notificationsApi;

export default notificationsApi;
