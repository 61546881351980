import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  // Store broadcast notifications that are temporary or in-progress
  broadcastNotifications: {},
};

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    // Add or update a broadcast notification
    addBroadcastNotification: (state, action) => {
      const notification = action.payload;
      state.broadcastNotifications[notification.uuid] = notification;
    },

    // Update an existing broadcast notification (mainly for progress updates)
    updateBroadcastNotification: (state, action) => {
      const notification = action.payload;
      
      // Update if exists, otherwise add
      if (state.broadcastNotifications[notification.uuid]) {
        state.broadcastNotifications[notification.uuid] = {
          ...state.broadcastNotifications[notification.uuid],
          ...notification,
        };
      } else {
        state.broadcastNotifications[notification.uuid] = notification;
      }

      // Remove completed progress notifications after delay
      if (notification.isTemporary && 
          (notification.progress === 100 || notification.type === 'error')) {
        setTimeout(() => {
          delete state.broadcastNotifications[notification.uuid];
        }, 5000);
      }
    },

    // Remove a broadcast notification
    removeBroadcastNotification: (state, action) => {
      delete state.broadcastNotifications[action.payload];
    },

    // Clear all broadcast notifications
    clearBroadcastNotifications: (state) => {
      state.broadcastNotifications = {};
    },

    // Handle import specific notifications
    handleImportProgress: (state, action) => {
      const { importId, progress, status } = action.payload;
      const uuid = `${importId}-progress`;

      state.broadcastNotifications[uuid] = {
        uuid,
        type: status === 'error' ? 'error' : 'info',
        heading: 'Import Progress',
        description: `Import is ${progress}% complete`,
        created_at: new Date().toISOString(),
        is_read: true,
        isTemporary: true,
        source: 'broadcast',
        progress,
        data: {
          importId,
          progress,
          status,
        },
      };

      // Remove completed imports
      if (progress === 100 || status === 'error') {
        setTimeout(() => {
          delete state.broadcastNotifications[uuid];
        }, 5000);
      }
    },
  },
});

// Export actions
export const {
  addBroadcastNotification,
  updateBroadcastNotification,
  removeBroadcastNotification,
  clearBroadcastNotifications,
  handleImportProgress,
} = notificationsSlice.actions;

// Export selectors
export const selectBroadcastNotifications = state => 
  Object.values(state.notifications.broadcastNotifications);

export const selectHasTemporaryNotifications = state =>
  Object.values(state.notifications.broadcastNotifications)
    .some(notification => notification.isTemporary);

export default notificationsSlice.reducer;
