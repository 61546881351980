import { createSlice } from '@reduxjs/toolkit';
import {
  createTemplate,
  getTemplates,
  getPaginatedTemplates,
} from '_api/admin/link-templates';
import { XHR_STATUS } from 'constants/xhr-status';
import { BE_TO_FE_INTERFACES } from 'store/interfaces.js';
import { pickAndRename } from 'utils/objectUtils.js';

const initialState = {
  templatesList: [],
  templatesListStatus: XHR_STATUS.idle,
  getTemplatesLoading: false,
  getTemplatesSuccess: false,
  getTemplatesError: false,
};

const linkTemplates = createSlice({
  name: 'templates',
  initialState,
  reducers: {},
  extraReducers: {
    [getPaginatedTemplates.pending]: state => ({
      ...state,
      templatesList: [],
      getTemplatesLoading: true,
      getTemplatesSuccess: false,
      getTemplatesError: false,
      templatesListStatus: XHR_STATUS.pending,
    }),
    [getPaginatedTemplates.fulfilled]: (state, action) => {
      const { data: templatesList = null, meta = {} } = action.payload ?? {};
      return {
        ...state,
        templatesList,
        getTemplatesLoading: false,
        getTemplatesSuccess: true,
        getTemplatesError: false,
        templatesListStatus: XHR_STATUS.fulfilled,
        meta: pickAndRename(meta, BE_TO_FE_INTERFACES.Meta),
      };
    },
    [getPaginatedTemplates.rejected]: state => ({
      ...state,
      getTemplatesLoading: false,
      getTemplatesSuccess: false,
      getTemplatesError: true,
      templatesListStatus: XHR_STATUS.rejected,
    }),

    [getTemplates.pending]: state => ({
      ...state,
      templatesList: [],
      getTemplatesLoading: true,
      getTemplatesSuccess: false,
      getTemplatesError: false,
      templatesListStatus: XHR_STATUS.pending,
    }),
    [getTemplates.fulfilled]: (state, action) => {
      const data = action.payload ?? {};
      return {
        ...state,
        templatesList: data,
        getTemplatesLoading: false,
        getTemplatesSuccess: true,
        getTemplatesError: false,
        templatesListStatus: XHR_STATUS.fulfilled,
      };
    },
    [getTemplates.rejected]: state => ({
      ...state,
      getTemplatesLoading: false,
      getTemplatesSuccess: false,
      getTemplatesError: true,
      templatesListStatus: XHR_STATUS.rejected,
    }),
    [createTemplate.fulfilled]: (state, action) => {
      const { data: newTemplateData = {} } = action.payload ?? {};

      return {
        ...state,
        templatesList: [...(state.templatesList || []), newTemplateData],
        createTemplate: {
          createTemplateLoading: false,
          createTemplateSuccess: true,
          createTemplateError: false,
        },
      };
    },
  },
});

export default linkTemplates.reducer;
