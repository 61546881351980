import Routes from 'routes';
import ThemeCustomization from 'themes';
import RTLLayout from 'components/RTLLayout';
import ScrollTop from 'components/ScrollTop';
import Snackbar from 'components/@extended/Snackbar';
import { LicenseInfo } from '@mui/x-license-pro';
import config from 'config';
import TenantGuard from 'middleware/TenantGuard';
import { FeatureProvider } from 'contexts/FeatureContext';
import { initServices } from 'services';

const { muiKey } = config;
LicenseInfo.setLicenseKey(muiKey);

await initServices();

const App = () => (
  <ThemeCustomization>
    <RTLLayout>
      <TenantGuard>
        <FeatureProvider>
          <ScrollTop>
            <>
              <Routes />
              <Snackbar />
            </>
          </ScrollTop>
        </FeatureProvider>
      </TenantGuard>
    </RTLLayout>
  </ThemeCustomization>
);

export default App;
