import { useDispatch } from 'react-redux';
import { usePrivateChannel } from './useBroadcasting';
import { transformBroadcastEvent } from '../utils/notificationTransformer';
import { 
  addBroadcastNotification, 
  updateBroadcastNotification, 
} from '../store/reducers/notifications';
import notificationsApi from '_api/notifications';
import { NOTIFICATION_TYPES } from '../utils/notifications/types';
import { getLinksList } from '_api/links';

const useBroadcastNotifications = (userUuid) => {
  const dispatch = useDispatch();

  // Generic broadcast handler
  const handleBroadcast = (event) => {
    const transformedNotification = transformBroadcastEvent(event);
    
    if (transformedNotification.isTemporary) {
      // Handle temporary notifications (like progress)
      dispatch(updateBroadcastNotification(transformedNotification));
    } else {
      // Handle persistent notifications
      dispatch(addBroadcastNotification(transformedNotification));
      if (event?.type === NOTIFICATION_TYPES.IMPORT.COMPLETED) {
        dispatch(getLinksList());
      }
    }

    // If notification needs backend sync, trigger refetch
    if (transformedNotification.needsSync) {
      dispatch(notificationsApi.util.invalidateTags(['Notification']));
    }
  };

  // Subscribe to user's private channel
  usePrivateChannel(`user.${userUuid}`, {
    // Import notifications
    [`.${NOTIFICATION_TYPES.IMPORT.PROGRESS}`]: handleBroadcast,
    [`.${NOTIFICATION_TYPES.IMPORT.COMPLETED}`]: handleBroadcast,
    [`.${NOTIFICATION_TYPES.IMPORT.ERROR}`]: handleBroadcast,
    [`.${NOTIFICATION_TYPES.IMPORT.STARTED}`]: handleBroadcast,
    
    // Generic notification handler for future features
    '.Notification': handleBroadcast,
  });
};

export default useBroadcastNotifications;
