import { configureStore } from '@reduxjs/toolkit';
import { useDispatch as useAppDispatch, useSelector as useAppSelector } from 'react-redux';

import { attributesApi } from '_api/attributes';
import { analyticsApi } from '_api/analytics';
import notificationsApi from '_api/notifications';
import { injectStore } from 'utils/axios';

import reducers from './reducers';

// ==============================|| REDUX TOOLKIT - MAIN STORE ||============================== //

const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem('analytics_filter', serializedState);
  } catch (err) {
    console.error(err);
  }
};

const store = configureStore({
  reducer: reducers,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat([attributesApi.middleware, analyticsApi.middleware, notificationsApi.middleware]),
});

store.subscribe(() => {
  saveState({
    analytics: store.getState().analytics,
  });
});

const { dispatch } = store;

const useDispatch = () => useAppDispatch();
const useSelector = useAppSelector;

injectStore(store);

export { store, dispatch, useSelector, useDispatch };
