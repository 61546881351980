import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from 'utils/axios';
import { removeEmptyValues } from 'utils/objectUtils';
import { cloneDeep } from 'lodash';
import { operatorsWithoutValue } from 'utils/dataGrid';


// TODO: For Pavlo (remove and unify)
export const normalizeFilters = (filters) => {
  const apiFilters = cloneDeep(filters);

  apiFilters.map((filter) => {
    if (filter.column === 'size') {
      filter.value = Number(filter.value) * 1024 * 1024;
    }
    return filter;
  });

  return apiFilters.filter(
    ({ value, operator }) => operatorsWithoutValue.includes(operator) ||
          (!Array.isArray(value) && value) ||
          (Array.isArray(value) && value.length),
  );
};

export const getLinksList = createAsyncThunk('links/getLinksList', async (shouldKeepPage, { rejectWithValue, getState }) => {
  try {
    const {
      auth: { domainID = '' },
      filters: {
        links: {
          filters: currentFilters = [],
          searchValue: currentSearchValue = '',
          sortData: { field: sort = 'id', sort: sort_direction = 'desc' } = {},
          filterLink: currentFilterLink = 'and',
        } = {},
      } = {},
      links: {
        linksListMeta: {
          currentPage = 0,
          perPage: currentRowCount = 25,
        },
      },
    } = getState();

    const normalizedFilters = normalizeFilters(currentFilters);
    //  search with keywords, check input value nullable
    const perPageIdxSearching = shouldKeepPage ?
      currentPage : Boolean(currentSearchValue.trim()) || normalizedFilters.length ? 0 : currentPage;

    const queryParams = {
      page: perPageIdxSearching,
      per_page: currentRowCount,
      search: currentSearchValue,
      filters: normalizeFilters(currentFilters),
      filter_link: currentFilterLink,
      sort,
      sort_direction,
    };

    const response = await axiosInstance.get(`/${domainID}/links`, {
      params: removeEmptyValues(queryParams),
      data: {},
    });

    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const createLink = createAsyncThunk(
  'links/createLink',
  async ({ linkData = {} }, { rejectWithValue, getState }) => {
    try {
      const {
        auth: { domainID = '' },
      } = getState();
      const response = await axiosInstance.post(`/${domainID}/links`, linkData);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const getLink = createAsyncThunk(
  'links/getLink',
  async ({ id }, { rejectWithValue, getState }) => {
    try {
      const {
        auth: { domainID = '' },
      } = getState();
      const response = await axiosInstance.get(`/${domainID}/links/${id}`);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const editLink = createAsyncThunk(
  'links/editLink',
  async ({ linkData = {}, linkID = '' }, { rejectWithValue, getState }) => {
    try {
      const {
        auth: { domainID = '' },
      } = getState();
      const response = await axiosInstance.put(`/${domainID}/links/${linkID}`, linkData);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const importLinks = createAsyncThunk(
  'links/importLinks',
  async ({ linksFileData = {} }, { rejectWithValue, getState }) => {
    try {
      const {
        auth: { domainID = '' },
      } = getState();
      const response = await axiosInstance.post(`/${domainID}/links/import/new`, linksFileData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);
export const getLinkAttributeMapping = createAsyncThunk(
  'links/getLinkAttributeMapping',
  async ({ uploadID = '' }, { rejectWithValue, getState }) => {
    try {
      const {
        auth: { domainID = '' },
      } = getState();
      const response = await axiosInstance.get(`/${domainID}/links/import/${uploadID}`);

      const invalidHeadersResponse = await axiosInstance.get(`/${domainID}/links/import/map/${uploadID}`);
      return { attributeMapping: response.data, invalidHeaders: invalidHeadersResponse.data };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const fillLinkImportData = createAsyncThunk(
  'links/fillLinkImportData',
  async ({ importData = {} }, { rejectWithValue, getState }) => {
    try {
      const {
        auth: { domainID = '' },
      } = getState();
      const response = await axiosInstance.post(`/${domainID}/links/import/new`, importData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const generateVariantsLink = createAsyncThunk(
  'links/generateVariantsLink',
  async ({ linkID, attributesList }, { rejectWithValue, getState }) => {
    try {
      const {
        auth: { domainID = '' },
      } = getState();

      const response = await axiosInstance.post(`/${domainID}/links/variants/${linkID}`, {
        assigned_attributes: attributesList,
      });

      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const duplicateLink = createAsyncThunk(
  'links/duplicateLink',
  async ({ linkID, count }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`/links/${linkID}/duplicate`, {
        count,
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const getRulesBaseStructure = createAsyncThunk('links/rules', async (_, { rejectWithValue, getState }) => {
  try {
    const {
      auth: { domainID = '' },
    } = getState();

    const response = await axiosInstance.get(`/${domainID}/links/rules`);
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});


export const resetLinkAnalytic = createAsyncThunk(
  'links/resetAnalytic',
  async ({ linkId }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`/links/${linkId}/reset-analytic`);

      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

