/* eslint import/no-mutable-exports: 0 */
import axiosInstance, { fetchCsrfToken } from 'utils/axios';
import { broadcastingService as baseBroadcastingService } from './broadcasting';
import { store } from 'store';
import config from 'config';

import HTTPRest from './HTTPRest';
import ELink from './ELink/ELink';
import QR from './QR/QR';
import Image from './Image/Image';
import Groups from './Groups/Groups';

let eLinksService;
let httpRestService;
let qrService;
let imageService;
let groupsService;
let broadcastingService;

const initServices = async () => {
  try {
    const csrfToken = await fetchCsrfToken();
    axiosInstance.defaults.headers.common['X-XSRF-TOKEN'] = csrfToken;
    // Initialize services
    broadcastingService = baseBroadcastingService.initialize(config, csrfToken, store);
    httpRestService = new HTTPRest(axiosInstance);
    eLinksService = new ELink(httpRestService);
    qrService = new QR(httpRestService);
    imageService = new Image(httpRestService);
    groupsService = new Groups(httpRestService);
    
    return true;
  } catch (error) {
    console.error('Failed to initialize services:', error);
    throw error;
  }
};

export { initServices, eLinksService, qrService, imageService, groupsService, broadcastingService };
