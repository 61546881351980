import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { broadcastingService } from '../services/broadcasting';

export const useChannel = (channelName, events, type = 'private') => {
  const { tenant } = useSelector(state => state.tenant);

  useEffect(() => {
    if (!channelName || !events) {
      return;
    }

    const fullChannelName = broadcastingService.getChannelName(channelName, tenant);
    const channel = broadcastingService.subscribeToChannel(fullChannelName, events, type);

    return () => {
      if (channel) {
        Object.keys(events).forEach((event) => {
          channel.stopListening(event);
        });
      }
    };
  }, [channelName, events, type, tenant]);
};

export const usePresenceChannel = (channelName, callbacks = {}) => useChannel(channelName, callbacks, 'presence');

export const usePrivateChannel = (channelName, events = {}) => useChannel(channelName, events, 'private');

export const usePublicChannel = (channelName, events = {}) => useChannel(channelName, events, 'public');
