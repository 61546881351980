export const BROADCASTER_TYPES = {
  PUSHER: 'pusher',
  REVERB: 'reverb',
};

export const getBroadcastingConfig = (config) => {
  const isPusher = config.broadcaster === BROADCASTER_TYPES.PUSHER;

  const commonConfig = {
    encrypted: false,
    forceTLS: false,
    withCredentials: true,
  };

  const pusherConfig = {
    ...commonConfig,
    key: config.pusherKey,
    cluster: config.pusherCluster,
    wsPort: config.pusherPort || 443,
    wssPort: config.pusherPort || 443,
    enabledTransports: ['ws'],
    disableStats: true,
  };

  const reverbConfig = {
    ...commonConfig,
    key: config.reverbAppKey,
    wsHost: config.reverbHost || 'localhost',
    wssHost: config.reverbHost || 'localhost',
    wsPort: config.reverbPort || 8080,
    wssPort: config.reverbPort || 8080,
    port: config.reverbPort || 8080,
  };

  return {
    driver: config.broadcaster,
    config: isPusher ? pusherConfig : reverbConfig,
  };
};

export const getAuthHeaders = (csrfToken, store) => {
  const { accessToken } = JSON.parse(localStorage.getItem('auth0Token')) || {};
  
  return {
    'X-XSRF-TOKEN': csrfToken,
    'X-Tenant': store.getState().tenant.tenant,
    'X-Domain': store.getState().auth.domainID,
    'X-Account': store.getState().auth.user?.account?.uuid,
    Authorization: `Bearer ${accessToken}`,
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
  };
};
