import { combineReducers } from 'redux';

import { attributesApi } from '_api/attributes';
import { analyticsApi } from '_api/analytics';

import menu from './menu';
import snackbar from './snackbar';
import auth from './auth';
import links from './links';
import templates from './link-templates';
import qrTemplates from './qr-templates';
import domains from './domains';
import admin from './admin';
import tenant from './tenant';
import notifications from './notifications';
import dashboard from './dashboard';
import filters from './filters';
import tableViews from './tableViews';
import tags from './tags';
import feature from './feature';
import analytics from './analytics';
import experimentalFeatures from './experimental-features';
import ipExclusions from './ip-exclusions';
import trustedDestinations from './trusted-destinations';
import pendingLinks from './pending-links';
import powerPages from './power-pages';
import roles from './roles';
import picklists from './picklists';
import notificationsApi from '_api/notifications';

// ==============================|| COMBINE REDUCERS ||============================== //

const reducers = combineReducers({
  auth,
  menu,
  snackbar,
  links,
  templates,
  qrTemplates,
  ipExclusions,
  domains,
  admin,
  tenant,
  notifications,
  dashboard,
  filters,
  tableViews,
  tags,
  feature,
  analytics,
  experimentalFeatures,
  trustedDestinations,
  pendingLinks,
  powerPages,
  roles,
  picklists,
  [attributesApi.reducerPath]: attributesApi.reducer,
  [analyticsApi.reducerPath]: analyticsApi.reducer,
  [notificationsApi.reducerPath]: notificationsApi.reducer,
});

export default reducers;
