export const NOTIFICATION_TYPES = {
  // Legacy types
  LEGACY: {
    SUCCESS: 'success',
    ERROR: 'error',
    WARNING: 'warning',
    INFO: 'info',
  },
  // Import specific types
  IMPORT: {
    STARTED: 'link-import-started',
    COMPLETED: 'link-import-completed',
    ERROR: 'link-import-error',
    PROGRESS: 'link-import-progression',
  },
};
  
export const NOTIFICATION_SOURCES = {
  LEGACY: 'legacy',
  LARAVEL: 'laravel',
  BROADCAST: 'broadcast',
};
