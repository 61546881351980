import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from 'utils/axios';
import { removeEmptyValues } from 'utils/objectUtils.js';
import { FETCH_PER_PAGE_COUNT } from '_api/constant.js';

export const getTemplates = createAsyncThunk('templates/getTemplates', async (_, { rejectWithValue }) => {
  try {
    const { data: { meta: { total } } } = await axiosInstance.get(`/templates?per_page=1&page=1`);
    const numberOfRequests = Math.ceil(total / FETCH_PER_PAGE_COUNT);

    const promises = [];
    for (let i = 0; i < numberOfRequests; i++) {
      promises.push(axiosInstance.get(`/templates?per_page=${FETCH_PER_PAGE_COUNT}&page=${i + 1}`));
    }

    const response = await Promise.all(promises);
    const templates = response.flatMap(item => item.data.data);


    return templates;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const getPaginatedTemplates = createAsyncThunk('templates/getPaginatedTemplates', async (data = null, { rejectWithValue }) => {
  try {
    const params = removeEmptyValues(data);
    const response = await axiosInstance.get(`/templates`, { params });

    return {
      data: response.data.data,
      meta: response.data.meta,
    };
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});


export const createTemplate = createAsyncThunk(
  'templates/createTemplate',
  async ({ templateData = {} }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`/templates`, templateData);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const editTemplate = createAsyncThunk(
  'templates/editTemplate',
  async ({ templateData = {}, templateUUID = '' }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.put(`/templates/${templateUUID}`, templateData);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const removeTemplate = createAsyncThunk(
  'templates/removeTemplate',
  async ({ templateUUID }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.delete(`/templates/${templateUUID}`);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const getTemplateById = createAsyncThunk('templates/getTemplateById', async ({ templateID = '' }, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.get(`/templates/${templateID}`);
    response.data.data.groups = response.data.data.groups.map(({ uuid, name }) => (
      { value: uuid, label: name, uuid }
    ));

    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});
