import { useMemo, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import useBroadcastNotifications from './useBroadcasts';
import { 
  useGetLegacyNotificationsQuery,
  useGetNotificationsQuery,
  useMarkNotificationsAsReadMutation,
  useMarkLegacyNotificationsAsReadMutation,
  useDeleteNotificationMutation,
  useDeleteLegacyNotificationMutation,
} from '_api/notifications';
import { removeBroadcastNotification } from 'store/reducers/notifications';
import { NOTIFICATION_SOURCES } from 'utils/notifications/types';
import { createSelector } from '@reduxjs/toolkit';

const selectNotificationsState = state => state.notifications;
const selectBroadcastNotificationsState = createSelector(
  selectNotificationsState,
  (notifications) => {
    const broadcastNotifications = notifications.broadcastNotifications || {};
    return Object.values(broadcastNotifications);
  },
);

const useNotifications = () => {
  const dispatch = useDispatch();
  const { user } = useSelector(state => state.auth);
  
  // Get notifications from all sources
  const { 
    data: legacyNotifications = [], 
    isLoading: isLegacyLoading, 
    error: legacyError, // Add error tracking
    refetch: refetchLegacy, // Add refetch capability
  } = useGetLegacyNotificationsQuery(undefined, {
    refetchOnMountOrArgChange: true,
    skip: !user?.uuid, // Skip if no user is logged in,
  });
  
  const { 
    data: notifications = [], 
    isLoading: isNotificationsLoading,
    error: notificationsError, // Add error tracking
    refetch: refetchNew, // Add refetch capability
  } = useGetNotificationsQuery(undefined, {
    refetchOnMountOrArgChange: true,
    skip: !user?.uuid, // Skip if no user is logged in,
  });
  
  const broadcastNotifications = useSelector(selectBroadcastNotificationsState);

  // Mutations
  const [markRead] = useMarkNotificationsAsReadMutation();
  const [markLegacyRead] = useMarkLegacyNotificationsAsReadMutation();
  const [deleteNotification] = useDeleteNotificationMutation();
  const [deleteLegacyNotification] = useDeleteLegacyNotificationMutation();

  // Setup broadcast handling
  useBroadcastNotifications(user?.uuid);

  // Combine and sort all notifications
  const allNotifications = useMemo(() => {
    if (!legacyNotifications && !notifications && !broadcastNotifications) {
      return [];
    }

    // Get all persisted notification UUIDs (both Laravel and legacy)
    const persistedUUIDs = new Set([
      ...(notifications || []).map(note => note.uuid),
      ...(legacyNotifications || []).map(note => note.uuid),
    ]);

    // Filter out broadcast notifications that already exist in persisted state
    const filteredBroadcasts = (broadcastNotifications || [])
      .filter(broadcast => !persistedUUIDs.has(broadcast.uuid));
    
    // Combine notifications, prioritizing persisted versions
    const combined = [
      ...filteredBroadcasts,
      ...(legacyNotifications || []),
      ...(notifications || []),
    ].sort((a, b) => new Date(b.created_at) - new Date(a.created_at));

    // Remove any remaining duplicates (should be rare after filtering)
    return combined.filter((notification, index, self) =>
      index === self.findIndex(note => note?.uuid === notification?.uuid),
    );
  }, [legacyNotifications, notifications, broadcastNotifications]);

  const unreadCount = useMemo(() => 
    allNotifications.filter(notification => !notification.is_read).length,
  [allNotifications],
  );

  // Mark all as read handler
  const markAllAsRead = useCallback(async () => {
    const legacyUnread = legacyNotifications
      .filter(notification => !notification.is_read)
      .map(notification => notification.uuid);

    const unread = notifications
      .filter(notification => !notification.is_read)
      .map(notification => notification.uuid);

    try {
      if (legacyUnread.length) {
        await markLegacyRead(legacyUnread);
      }
  
      if (unread.length) {
        // Make sure we're sending an array in the proper format
        await markRead({ notifications: unread });
      }
      refetchNew();
      refetchLegacy();
    } catch (error) {
      console.error('Failed to mark notifications as read:', error);
    }
  }, [legacyNotifications, notifications, markLegacyRead, markRead]);

  // Delete notification handler
  const handleDelete = useCallback(async (uuid, source) => {
    try {
      switch (source) {
        case NOTIFICATION_SOURCES.LEGACY:
          await deleteLegacyNotification(uuid);
          break;
        
        case NOTIFICATION_SOURCES.LARAVEL:
          await deleteNotification(uuid);
          break;
        
        case NOTIFICATION_SOURCES.BROADCAST:
          dispatch(removeBroadcastNotification(uuid));
          break;
          
        default:
          console.warn('Unknown notification source:', source);
      }
    } catch (error) {
      console.error('Failed to delete notification:', error);
    }
  }, [deleteNotification, deleteLegacyNotification, dispatch]);

  // Log any errors
  useEffect(() => {
    if (legacyError) {
      console.error('Legacy notifications error:', legacyError);
    }
    if (notificationsError) {
      console.error('New notifications error:', notificationsError);
    }
  }, [legacyError, notificationsError]);

  return {
    notifications: allNotifications,
    unreadCount,
    markAllAsRead,
    deleteNotification: handleDelete,
    isLoading: isLegacyLoading || isNotificationsLoading,
    hasError: Boolean(legacyError) || Boolean(notificationsError),
    refetch: useCallback(() => {
      refetchLegacy();
      refetchNew();
    }, [refetchLegacy, refetchNew]),
  };
};

export default useNotifications;
